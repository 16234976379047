p:first-child {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

.Update__chaser {
  display: flex;
}
.Update__chaser img {
  max-height: 40px;
  max-width: 40px;
  object-fit: cover;
  border-radius: 20px;
  margin-right: 16px;
}
.Update__chaser {
  padding: 16px;
}
.Update__meta p {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  margin: 0;
}
.Update__meta p {
  color: #2c3248;
}
.Update__meta p a {
  color: #1e9bf5;
}
.Update__chaser p {
  color: #6f758f;
}
.Update__chaser strong {
  font-weight: 600;
  color: #2c3248;
}

button {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  background: none;

  outline: none;
}
button[disabled] {
  opacity: 0.25;
}
button svg {
  display: block;
}
.Livestreams button {
  border-radius: 9px;
}
