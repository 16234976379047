:root {
  --lightGrey2: #e8ebf2;
  --lightGrey3: #f5f6fa;

  --skyBlue2: #1e9bf5;
}

.ChaserMarker {
  z-index: 1;
}
.ChaserPin {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.ChaserPin--live path {
  fill: #c52a15;
}
.ChaserPin--playing path {
  fill: white;
}
.Avatar {
  cursor: pointer;
  position: absolute;
  top: 4px;
  left: 4px;
}
.ChaserPin--live .Avatar img {
  border: 3px solid #ff553e;
}
.Avatar img,
.Avatar .Avatar__blank {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 20px;
  display: block;
}
.Avatar__blank {
  background: #a8b1cf;
}
.FakeAvatar,
.Avatar .FakeAvatar {
  color: #e8ebf2;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.FakeAvatar {
  font-size: 1.1em;
}
.ChaserPin > span {
  color: white;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  background: #ff553e;
  display: block;
  width: auto;
  position: absolute;
  bottom: 10px;
  margin-top: auto;
  padding: 2px 5px;
  border-radius: 2px;
}

.HHMap {
  position: relative;
}
.HHPanel {
  flex-shrink: 0;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #15192f;
  background: #2c3248;
  z-index: 2;
  padding: 0 20px;

  color: #f5f6fa;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.02em;
}
.HHPanel h2 {
  margin: 0.2em 0 0 0;
  font-size: 32px;
}

.HHPanel dt {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #a8b1cf;
  opacity: 0.75;
  padding: 16;
}
.HHPanel dd {
  margin: 0;
  padding: 0;
}

.HHMarker {
  position: relative;
}
.HHMarker svg + img {
  object-fit: center;
  border-radius: 14px;
  position: absolute;
  top: 3px;
  left: 3px;
}
.HHPanel dl > div {
  margin: 0 0 20px 0;
}
.HHPanel dl > div:not(:first-child) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.HHPanel dd {
  display: flex;
  align-items: center;
}
.HHPanel dd svg {
  order: 2;
  margin-left: 20px;
}
@media (min-width: 1024px) {
  .HHPanel {
    padding: 40px 7.5% 32px;
  }
  .HHPanel h2 {
    margin: -0.2em 0 0 0;
  }
  .HHPanel dl {
    display: flex;
  }
  .HHPanel dl > div:first-child {
    width: 25%;
  }
  .HHPanel dt {
    margin: 0 0 1em 0;
  }
  .HHPanel dl > div:not(:first-child) {
    display: block;
    margin-bottom: 0;
  }
  .HHPanel dl > div {
    margin-right: 7.5%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .HHPanel dd {
    display: block;
  }
  .HHPanel dd svg {
    margin-right: 16px;
    vertical-align: middle;
    margin-bottom: 3px;
    margin-left: 0;
  }
}

.Button {
  display: flex;
  align-items: center;
  border: 1px solid #1e9bf5;
  color: #1e9bf5;
  background: none;
  padding: 0.5em 1em;
  margin: 0 1em;

  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}
.Button svg {
  margin-right: 1em;
}

.Panel__TimeLoc p {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #a8b1cf;
  margin: 0;
}
.Panel__TimeLoc {
  display: flex;
}
.Panel__TimeLoc div:first-child {
  margin-right: 0.25em;
}

.Bounds {
  position: fixed;
  bottom: 36px;
  right: 16px;
  z-index: 2;
  background: #515869;
  padding: 8px;
  transition: background-color 0.2s;
}

.Bounds--set {
  background: #1e9bf5;
}
.Bounds svg,
.Bounds button {
  display: block;
  cursor: pointer;
}
.DebugPanel {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 3;
  background: rgba(25, 35, 55, 0.75);

  display: flex;
  align-items: center;
  cursor: auto;
  padding: 12px 16px;
}
.DebugPanel__time span {
  font-style: normal;
  font-size: 11px;
  color: #f5f6fa;
  opacity: 0.6;
  text-transform: uppercase;
  display: block;
  margin-bottom: 2px;
}
.DebugPanel__time time {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #f5f6fa;
}
.DebugPanel--old {
  background: rgba(111, 117, 143, 0.75);
}
.DebugPanel pre {
  margin: 0.88em 1em;
  font-size: 13px;
}
.DebugPanel button {
  display: inline-block;
  text-transform: uppercase;
  background: #1e9bf5;
  color: white;
  font-weight: 600;
  cursor: pointer;
  padding: 6px 12px;
  margin-left: 20px;
  font-size: 13px;
}
.DebugPanel button:active {
  opacity: 0.5;
}

.SelectedVideo {
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
}
.SelectedVideo video {
  max-width: 50vw;
  max-height: 50vh;
}
video:focus,
video:active {
  outline: none;
}
.Livestreams video::-webkit-media-controls-timeline,
.Livestreams video::-webkit-media-controls-current-time-display,
.Livestreams video::-webkit-media-controls-timeline-container,
.Livestreams video::-webkit-media-controls-time-remaining-display,
/* Todo: figure out the classes here, so we don't have to wrap .Live (that's a bad class name anyways */
.Live video::-webkit-media-controls-timeline,
.Live video::-webkit-media-controls-current-time-display,
.Live video::-webkit-media-controls-timeline-container,
.Live video::-webkit-media-controls-time-remaining-display,
.LiveStreamPanel:not(.replay) video::-webkit-media-controls-timeline,
.LiveStreamPanel:not(.replay) video::-webkit-media-controls-current-time-display,
.LiveStreamPanel:not(.replay) video::-webkit-media-controls-timeline-container,
.LiveStreamPanel:not(.replay) video::-webkit-media-controls-time-remaining-display {
  display: none;
}

.HHMarker figure {
  background: #606980;
}
.HHMarker figcaption {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  /* identical to box height */
  color: white;
  text-align: center;

  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-bottom: 0.5em;
}
.HHMarker--update:hover .Update__meta {
  display: block;
}
.Update__pin {
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.Update__pin--selected svg path.pin-bg {
  fill: white;
}
.Update__pin__video {
  position: absolute;
  top: 11px;
  left: 13px;
}

.Update__pin__video path {
  fill: #1e9bf5;
  stroke: #192337;
  stroke-width: 1px;
}
.Update__pin--selected .Update__pin__video path {
  fill: white;
}

.UpdatePanel {
  margin: 24px 24px 0 16px;
}

.Sidebar {
  pointer-events: none;
}
.Livestreams {
  pointer-events: auto;
  flex-shrink: 0;
}
.HHMap--replay .UpdatePanel {
  margin: 0;
  display: flex;
  flex-direction: column;
}
.Update__meta {
  position: relative;
  background: var(--lightGrey3);
  max-width: 85vw;
  pointer-events: auto;
  flex: 0;
  max-height: calc(100vw - 100px);
  margin-top: 56px;
}
.Update__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.Update__controls {
  display: flex;
  justify-content: space-between;
}
.Update__controls button {
  height: 48px;
  width: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s;
}
.Update__controls button:not([disabled]) {
  cursor: pointer;
}
.Update__controls button:not([disabled]):hover {
  background: var(--lightGrey2);
}
.Update__controls button svg path {
  transition: fill 0.2s;
}

.Update__controls button:not([disabled]):hover svg path {
  fill: var(--skyBlue2);
}

.Update__golive {
  /* background: #f5553f;
  color: white; */

  border: 1px solid #f5553f;
  color: #f5553f;

  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  padding: 6px 12px 6px;
  font-size: 13px;

  align-self: center;

  margin-right: auto;
}

.Update__close {
  display: block;
  height: 32px;
  width: 32px;
  position: absolute;
  right: -16px;
  top: -16px;
  z-index: 9;
  cursor: pointer;
}
.Update__close img {
  display: block;
}
.Update__meta figure {
  margin: 0;
  display: block;
}
.Update__meta figure img {
  display: block;
  max-width: 100%;
}
.Update__meta .Video {
  position: static;
}
.Update__meta .Video video {
  max-width: 100%;
  max-height: calc(85vh - 100px); /* TODO: fix heights */
  display: block;
}
@media (min-width: 1024px) {
  .Update__meta {
    min-width: 270px;
    margin-top: 0;
  }
  .Update__meta .Video video {
    min-width: 355px;

    max-width: 40vw;
  }
  .Update__meta--text,
  .Update__meta--attachment {
    max-width: 33vw;
  }
}
.Update__meta figcaption {
  background: var(--lightGrey3);
  padding: 1em;
}
.Update__meta figcaption p {
  max-width: 320px;
}
.Update__meta figcaption:empty {
  display: none;
}
.Update__text {
  color: #343e5c !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.Sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  z-index: 2;
}

.Livestreams {
  overflow-y: auto;
  padding-bottom: 5px;
  scrollbar-width: thin;
  scrollbar-color: rgba(111, 117, 143, 0.4) #192337;
}
.Livestreams::-webkit-scrollbar {
  width: 15px; /* width of the entire scrollbar */
}
.Livestreams::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
.Livestreams::-webkit-scrollbar-thumb {
  border-radius: 8px; /* roundness of the scroll thumb */
  border: 5px solid #192337; /* creates padding around scroll thumb */
  opacity: 0;
  transition: opacity 0.3s;
}

.Livestreams:hover::-webkit-scrollbar-thumb {
  opacity: 1;
  background-color: rgba(111, 117, 143, 0.4); /* color of the scroll thumb */
}

.HHMap--replay .Sidebar {
  z-index: 11;
  max-height: 100%;
}

.Livestreams {
  background: #192337;
  padding: 5px;
  min-width: 240px;
  position: relative;
}
.Livestreams--hidden {
  min-width: 0;
}
.Livestreams__logo {
  display: block;
}
.Livestreams--hidden .Livestreams__logo {
  display: none;
}
.Livestreams h4 {
  margin: 1em 0 0 0;
  color: white;
  padding: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
}
.Livestreams--hidden h4 {
  display: none;
}
.Livestreams h4:not(:first-of-type) {
  opacity: 0.6;
}
.Livestreams header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 11px 5px;
}
.Livestreams header button {
  margin-top: -1px;
}
.Livestreams--hidden header {
  justify-content: center;
}
.Livestreams h2 {
  font-family: Fira Sans Condensed;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  /* or 17px */

  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: white;
  margin: 0;
}
.Livestreams--hidden h2 {
  display: none;
}
.Livestreams video {
  max-width: 140px;
}

.Livestreams--hidden .Join {
  display: none;
}
.Join {
  background: #2c3248;
  margin: 0 10px 0 5px;
  position: absolute;
  min-width: 220px;
  padding: 10px 15px;
}
.Live__stream + .Join {
  margin-top: 20px;
}
.Join h2 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #d6dbe6;
  text-transform: none;
  margin: 0.5em 0 0 0;
}
.Join p {
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #a2aac1;
}
.Join a,
.JoinToday {
  display: inline-block;
  text-decoration: none;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  /* Sky Blue 2 */

  color: #1e9bf5;
}
.JoinToday {
  color: white;
  display: inline-block;
  line-height: normal;
  background: #2c3248;
  padding: 0 5px;
  margin-right: 5px;
}
.Livestreams header > div:last-child {
  display: flex;
  align-items: center;
}

.Live__stream {
  position: relative;
}
.Live__chaser {
  z-index: 2;
  display: flex;
  padding: 5px;
  border-radius: 0.5em;
  cursor: pointer;
  max-width: 240px;
  text-decoration: none;
}
.Live__chaser--offline {
  align-items: center;
}
.Live__chaser--offline .FakeAvatar,
.Live__chaser--offline > img,
.Live__chaser--offline p:first-child {
  opacity: 0.5;
}

.Live__chaser p a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}
.Live__chaser:not(.Live__chaser--offline) p a {
  margin-top: 1em;
}
.Live__chaser--tracking {
  background: rgb(162, 170, 193, 0.15);
  cursor: default;
  border-radius: 1px;
}
.Live__chaser img,
.Live__chaser .FakeAvatar,
.Update__chaser .FakeAvatar {
  margin-right: 0.5rem;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  object-fit: cover;
  border: 2px solid #6f758f;
}
.Live__chaser--offline img,
.Live__chaser--offline .FakeAvatar {
  border: 2px solid #192337;
}
.Update__chaser .FakeAvatar {
  margin-right: 15px;
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
.Live__chaser .FakeAvatar {
  font-size: smaller;
}
.Live__chaser--live img {
  border: 2px solid #ff553e;
}
.Live__chaser--live {
  position: relative;
}
.Live__chaser--live:after {
  position: absolute;
  top: 50%;
  right: 16px;
  content: '';
  display: block;
  height: 6px;
  width: 6px;
  margin-top: -3px;
  border-radius: 3px;
  background: #ff553e;
}
.Livestreams--hidden .Live__chaser img,
.Livestreams--hidden .Live__chaser .FakeAvatar {
  margin-right: 0;
}
.Livestreams--hidden .Live__chaser--live:after {
  display: none;
}
.Live__chaser p {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 130%;
  color: #e8ebf2;
  margin: 0;
}
.Live__chaser p + p {
  font-weight: 400;
  color: #a2aac1;
}

.HHMap--replay {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.HHMap--replay .HHReplay {
  position: relative;
  height: auto !important;
  flex: 1;
}
.Panel {
  flex: 0;
}
.Timeline {
  flex-shrink: 0;
  position: relative;
  background: #192337;
  color: white;
  min-height: 50px;
  padding: 32px;
}
.Timeline__updates {
  width: 100%;
  height: 14px;
  padding: 0 0;
  position: relative;
}
.Timeline__update {
  background: #1e9bf5;
  position: absolute;
  height: 6px;
  width: 6px;
  margin-left: -3px;
  border-radius: 3px;
  z-index: 2;
  cursor: pointer;
}
.Timeline__update--live {
  background: #ff553e;
  margin-left: -2px;
}
.Timeline__scubber {
  width: 100%;
  height: 20px;
  background: #2c3248;
  position: relative;
  border: 0;
  border-top: 6px solid #192337;
  border-bottom: 8px solid #192337;
  cursor: pointer;
}
.Timeline__progress {
  height: 6px;
  background: #1e9bf5;
  position: absolute;
  left: 0;
}
.Timeline__progress--live {
  background: #ff553e;
}
.Timeline__hover {
  height: 6px;
  background: #1e9bf5;
  opacity: 0.25;
  position: absolute;
  left: 0;
}
.Timeline__rabbit {
  height: 12px;
  width: 12px;
  display: inline-block;
  position: absolute;
  right: -6px;
  top: -3px;
  border-radius: 6px;
  background: #1e9bf5;
  pointer-events: none;
}
.Rabbit {
  height: 16px;
  width: 16px;
  display: inline-block;
  border-radius: 8px;
  background: #1e9bf5;
  border: 2px solid white;
  box-shadow: 0 0 0 2px #15192f;
}
@media (min-width: 1024px) {
  .Timeline {
    padding: 32px 64px;
  }
}
